import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Microdata, getMetatagContent, getLogo, getImageUrl } from '../Microdata'
import { DrupalTemplate, DrupalNode, DrupalProcessedText, DrupalMediaImage, DrupalBanner } from '../drupal'
import { widths } from '../style/variables'
import Layout from '../layouts'
import Container from '../components/Container'
import Stack from '../components/Stack'
import Banner from '../components/Banner'
import Media from '../components/fields/Media'

export interface ArticleNode extends DrupalNode {
	body: DrupalProcessedText
	created: string
	relationships: {
		field_media: DrupalMediaImage
		field_banner?: DrupalBanner
	}
}

const StyledContainer = styled(Container)`
	/** Make content more readable by limiting max-width. */
	article {
		max-width: ${widths.md}px;
		margin: 0 auto;
	}
	article p img {
		width: 100%;
		height: auto;
	}
`

function makeArticleMicrodata(node: ArticleNode): Microdata {
	const siteName = getMetatagContent(node.metatag_normalized, 'property', 'og:site_name')

	return [
		{
			'@context': 'http://schema.org',
			'@type': 'Article',
			name: getMetatagContent(node.metatag_normalized, 'name', 'title'),
			image: getImageUrl(node.relationships.field_media),
			description: getMetatagContent(node.metatag_normalized, 'name', 'description'),
			author: {
				'@type': 'Organization',
				name: siteName
			},
			datePublished: node.created,
			headline: node.title,
			publisher: {
				'@type': 'Organization',
				name: siteName,
				logo: {
					'@type': 'ImageObject',
					url: getLogo()
				}
			}
		}
	]
}

const ArticleTemplate: DrupalTemplate<ArticleNode> = ({ data: { node } }) => (
	<Layout node={node} microdata={makeArticleMicrodata(node)}>
		<main>
			<Stack>
				<Banner image={node.relationships.field_banner} />
				<StyledContainer>
					<article>
						<Media source={node.relationships.field_media} />
						<h1>{node.title}</h1>
						<div dangerouslySetInnerHTML={{ __html: node.body.processed }} />
					</article>
				</StyledContainer>
			</Stack>
		</main>
	</Layout>
)

export default ArticleTemplate

export const pageQuery = graphql`
	query ArticleTemplateQuery($drupalId: String!) {
		node: nodeArticle(drupal_id: { eq: $drupalId }) {
			langcode
			title
			created
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			body {
				processed
			}
			relationships {
				field_media {
					__typename
					field_media_image {
						alt
					}
					relationships {
						field_media_image {
							localFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
				field_banner {
					...DrupalBanner
				}
			}
		}
	}
`
